import React, { FC, useEffect } from "react";
import FormLayout from "./formLayout";
import { FormGroup } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { AMP_EVENTS, AMP_PROPERTIES } from "../../../services/types";
import { useAnalytics } from "../../../services/analytics";
import ActionRedirect from "../components/ActionRedirect";
import { BusinessSizeOptions } from "./config";
import { IBasicStepProps } from "../types";
import Selection from "../../../components/Selection";

const BusinessSize: FC<IBasicStepProps> = ({
  animated,
  decrementStep,
  formik,
  incrementStep,
  springProps,
  t,
}) => {
  const classes = useStyles({});
  const { trackAnalyticsEvent, trackGAPage } = useAnalytics();
  const options = BusinessSizeOptions?.map((option, index) => {
    const isActive = formik?.values?.businessSize.has(option.value);
    return (
      <Selection
        key={option.key}
        className={classes.selectionItem}
        label={t(option?.label, { ns: "onboarding" })}
        onChange={() => {
          if (isActive) {
            if (formik.values.businessSize?.delete(option.value)) {
              formik.setFieldValue("businessSize", formik.values.businessSize);
            }
          } else {
            formik.setFieldValue(
              "businessSize",
              formik.values.businessSize?.add(option.value)
            );
          }
        }}
        isSelected={isActive}
        value={option.value}
      />
    );
  });
  useEffect(() => {
    try {
      trackGAPage(AMP_EVENTS.onboardingBusinessSize);
    } catch (err) {
      console.error(err);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <animated.div style={springProps}>
      <FormLayout
        subtitle={t("BUSINESS_SIZE.SUBHEADING", { ns: "onboarding" })}
        title={t("BUSINESS_SIZE.HEADING", { ns: "onboarding" })}
      >
        <FormGroup>{options}</FormGroup>
        <ActionRedirect
          goToPreviousStep={decrementStep}
          goToNextStep={() => {
            try {
              trackAnalyticsEvent(AMP_EVENTS.onboardingBusinessSize, {
                [AMP_PROPERTIES.option]: formik.values.businessSize,
              });
            } catch (err) {
              console.error(err);
            }
            incrementStep();
          }}
          primaryLabel={t("BUSINESS_SIZE.PRIMARY_CTA", {
            ns: "onboarding",
          })}
        />
      </FormLayout>
    </animated.div>
  );
};

const useStyles = makeStyles((theme) => ({
  selectionItem: {
    marginBottom: "8px",
  },
}));

export default BusinessSize;
