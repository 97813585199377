export enum AMP_EVENTS {
  checkoutCancel = "user_cancel_checkout",
  checkoutError = "user_checkout_error",
  onboardingBusinessAccounts = "user_completed_onboarding_business_accounts",
  onboardingBusinessActivities = "user_completed_onboarding_business_activities",
  onboardingBusinessCommute = "user_completed_onboarding_business_commute",
  onboardingBusinessMeal = "user_completed_onboarding_business_meal",
  onboardingBusinessSize = "user_completed_onboarding_business_size",
  onboardingBusinessStructure = "user_completed_onboarding_business_structure",
  onboardingBusinessTravel = "user_completed_onboarding_business_travel",
  onboardingCaptureEmail = "user_completed_onboarding_capture_email",
  onboardingConnectBank = "user_completed_onboarding_connect_bank",
  onboardingCogs = "user_completed_onboarding_cogs",
  onboardingCreateAccount = "user_completed_onboarding_create_account",
  onboardingCurrentResidence = "user_completed_onboarding_current_residence",
  onboardingError = "user_completed_onboarding_error",
  onboardingExpectedOutcomes = "user_completed_onboarding_expected_outcomes",
  onboardingHomeOfficeSqFt = "user_completed_onboarding_home_office_sq_ft",
  onboardingHomeUsage = "user_completed_onboarding_home_usage",
  onboardingImportingTransactions = "user_completed_onboarding_importing_transactions",
  onboardingIncomeSource = "user_completed_onboarding_income_source",
  onboardingOperatingSystem = "user_completed_onboarding_operating_system",
  onboardingPhoneUsage = "user_completed_onboarding_phone_usage",
  onboardingPushNotifications = "user_completed_onboarding_push_notifications",
  onboardingSalesTax = "user_completed_onboarding_sales_tax",
  onboardingStartedBusiness = "user_completed_onboarding_started_business",
  onboardingSuccess = "user_completed_onboarding_success",
  onboardingSummaryOverview = "user_completed_onboarding_summary_overview",
  onboardingWorkspace = "user_completed_onboarding_workspace",
  onboardingVehicleUsage = "user_completed_onboarding_vehicle_usage",
}

export enum AMP_PROPERTIES {
  accounts = "accounts",
  businessActivities = "business activities",
  fields = "fields",
  institution = "institution",
  isBusiness = "is business",
  option = "option",
  selections = "selections",
  source = "source",
  sqft = "sq ft",
  transactionId = "transaction id",
  type = "type",
  usage = "usage",
}

export enum BENJI_API_KEYS {
  BANK_CONNECTIONS = "BANK_CONNECTIONS",
  BANNERS = "BANNERS",
  BENJI_CATEGORIES = "BENJI_CATEGORIES",
  BUSINESSES = "BUSINESSES",
  COUNTRIES = "COUNTRIES",
  MAILCHIMP_USER_EXISTS = "MAILCHIMP_USER_EXISTS",
  PAYMENT_INIT = "PAYMENT_INIT",
  USER = "USER",
  USER_LAST_LOGIN = "USER_LAST_LOGIN",
  USER_SETTINGS = "USER_SETTINGS",
  PROFESSIONS = "PROFESSIONS",
  REGIONS = "REGIONS",
  TRANSACTION_COUNT = "TRANSACTION_COUNT",
}

export enum PLAID_API_KEYS {
  CREATE_LINK_TOKEN = "CREATE_LINK_TOKEN",
  PLAID_INSTITUTION = "PLAID_INSTITUTION",
}

export interface IFetchUserSettingsResponse {
  data: Array<{ key: string; value: string }>;
}

export interface IPostBankAccountArgs {
  accounts: Array<{
    id: string;
    is_business: boolean;
  }>;
  bank_item_id: string;
  business_id: string;
  access_token: string;
}

export interface IPostBankConnectionArgs {
  accounts: Array<{
    id: string;
    is_business: boolean;
  }>;
  institution_id: string;
  business_id: string;
  public_token: string;
}

export interface IPostCaptureEmailArgs {
  country: string;
  email: string;
  firstName?: string;
  lastName?: string;
  tagType: string;
}
export interface IPostPlaidLinkEvent {
  error_code: string;
  error_type: string;
  link_session_id: string;
  request_id: string;
  status: string;
  type: string;
}
export interface IPostSignUpArgs {
  business_commute: Array<string>;
  business_meal: boolean;
  business_size: Array<string>;
  business_start: string;
  business_travel: boolean;
  cogs: boolean;
  country_id: string;
  email: string;
  full_name: string;
  home_office_sq_ft: number;
  home_usage_percentage: number;
  income_sources: Array<string>;
  password: string;
  phone_usage: number;
  occupations: Array<string>;
  referral_code?: string;
  region_id: string;
  sales_tax: string;
  signup_code?: string;
  timezone: string;
  signup_source: string;
  workspace: string;
}

export interface IOccupation {
  emoji: string;
  id: string;
  name: string;
}

export interface IRegion {
  country_id: string;
  id: string;
  name: string;
  region_code: string;
}

export interface IUpdateUserArgs {
  fcm_token?: string;
  last_login?: boolean;
}

export interface Business {
  id: string;
  country: {
    id: string;
    name: string;
    country_code: string;
  };
  ownerId: string;
  type: string;
}

export interface BankAccount {
  bankInstitutionId: string;
  id: string;
  isBusiness: boolean;
  mask: string;
  name: string;
  plaidAccountId?: string;
  plaidAccountIdStr?: string;
}

export interface Connection {
  accounts: Array<BankAccount>;
  institution: BankInstitution;
}

export interface BankInstitution {
  accessToken: string;
  businessId: string;
  id: string;
  plaidInstitutionId: string;
  plaidItemId: string;
}

export interface Profile {
  id: string;
  email: string;
  firstName: string;
  lastName: string;

  country: {
    id: number;
    name: string;
    country_code: string;
  };

  profile: {
    id: string;
    language: string;
    cron_timezone: string;
    iana_timezone: string;
    fcm_token: string;
  };

  settings: {
    key: string;
    value: string;
  }[];
}
