import React, { FC, useEffect } from "react";
import FormLayout from "./formLayout";
import { FormGroup } from "@mui/material";
import { AMP_EVENTS, AMP_PROPERTIES } from "../../../services/types";
import { useAnalytics } from "../../../services/analytics";
import Option from "../../../components/Option";
import ActionRedirect from "../components/ActionRedirect";
import { COGSOptions } from "./config";
import { IBasicStepProps } from "../types";

const COGS: FC<IBasicStepProps> = ({
  animated,
  decrementStep,
  formik,
  incrementStep,
  springProps,
  t,
}) => {
  const { trackAnalyticsEvent, trackGAPage } = useAnalytics();
  const options = COGSOptions?.map((option) => {
    const isActive = formik?.values?.cogs === option.value;
    return (
      <Option
        key={`option_${option.key}`}
        label={t(option?.label, { ns: "onboarding" })}
        onClick={() => {
          formik.setFieldValue("cogs", option.value);
          try {
            trackAnalyticsEvent(AMP_EVENTS.onboardingCogs, {
              [AMP_PROPERTIES.option]: formik.values.cogs,
            });
          } catch (err) {
            console.error(err);
          }
          incrementStep();
        }}
        selected={isActive}
        value={option?.value}
      />
    );
  });
  useEffect(() => {
    try {
      trackGAPage(AMP_EVENTS.onboardingCogs);
    } catch (err) {
      console.error(err);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <animated.div style={springProps}>
      <FormLayout
        subtitle={t("COST_OF_GOODS_SOLD.SUBHEADING", { ns: "onboarding" })}
        title={t("COST_OF_GOODS_SOLD.HEADING", { ns: "onboarding" })}
      >
        <FormGroup>{options}</FormGroup>
        <ActionRedirect goToPreviousStep={decrementStep} />
      </FormLayout>
    </animated.div>
  );
};

export default COGS;
