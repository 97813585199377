import React, { FC, useEffect, useState } from "react";
import FormLayout from "../../../screens/Onboarding/steps/formLayout";
import makeStyles from "@mui/styles/makeStyles";
import {
  AMP_EVENTS,
  AMP_PROPERTIES,
  BENJI_API_KEYS,
  IPostBankConnectionArgs,
} from "../../../services/types";
import { useAnalytics } from "../../../services/analytics";
import { useMutation, useQuery } from "react-query";
import * as Sentry from "@sentry/react";
import ActionRedirect from "../../../screens/Onboarding/components/ActionRedirect";
import { IBasicStepProps } from "../types";
import { useBankConnectionStore } from "../../../stores/bankConnection";
import {
  fetchBusinesses,
  postBankConnection,
  postUserSetting,
} from "../../../services/benji";
import { transformBenjiBusiness } from "../../../services/transformers";
import { Box } from "@mui/material";
import ToggleSwitch from "../../../components/ToggleSwitch";
import Text, { TextVariant } from "../../../components/Text";
import { useOnboardingStore } from "../../../stores/onboarding";
import defaultTheme from "../../../styles/theme";
import ErrorToast from "../../../components/ErrorToast";
import { useNavigate } from "react-router";
import { ProfileSettingOnboardingStep, ProfileSettings } from "src/types/enums";

const BusinessAccounts: FC<IBasicStepProps> = ({
  animated,
  springProps,
  t,
}) => {
  const classes = useStyles({});
  const { trackAnalyticsEvent, trackGAPage } = useAnalytics();
  const navigate = useNavigate();
  const [personalAccountIds, setPersonalAccountIds] = useState<Array<string>>(
    []
  );
  const { errorCode, setErrorCode } = useBankConnectionStore((state) => ({
    errorCode: state.errCode,
    setErrorCode: state.setErrCode,
  }));
  const { accountMap } = useOnboardingStore((state) => ({
    accountMap: state.accountMap,
  }));
  const addBankConnection = useMutation(
    (newBankConnection: IPostBankConnectionArgs) =>
      postBankConnection(newBankConnection),
    {
      onError: (err) => {
        console.error(err);
        Sentry.captureException(err);
      },
      onSuccess: (data) => {
        if (data?.err_code) {
          setErrorCode(data?.err_code);
          Sentry.captureMessage(`${data?.err_code}`);
        } else {
          setErrorCode("");
          navigate("/download");
        }
      },
    }
  );
  const userSettingMutation = useMutation(
    () =>
      postUserSetting(
        ProfileSettings.ONBOARDING_STEP,
        ProfileSettingOnboardingStep.ONBOARDING_STEP_NOTIFICATION
      ),
    {
      onError: (err) => {
        console.error(err);
        Sentry.captureException(err);
      },
    }
  );

  const { data: businesses } = useQuery(
    BENJI_API_KEYS.BUSINESSES,
    () => fetchBusinesses(),
    {
      onError: (err) => {
        console.error(err);
        Sentry.captureException(err);
      },
      select: (data) => data?.map(transformBenjiBusiness),
    }
  );

  useEffect(() => {
    try {
      trackGAPage(AMP_EVENTS.onboardingBusinessAccounts);
    } catch (err) {
      console.error(err);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = async () => {
    const args: IPostBankConnectionArgs = {
      accounts: accountMap?.[0]?.accounts?.map((account: any) => ({
        id: account?.id,
        is_business: !!personalAccountIds?.find((id) => id === account?.id),
      })),
      institution_id: accountMap?.[0]?.institution?.institution_id || "",
      business_id: businesses[0].id,
      public_token: accountMap?.[0]?.publicToken,
    };

    setErrorCode("");
    userSettingMutation.mutate();
    addBankConnection.mutate(args);
  };

  const renderItems = () => {
    return accountMap?.[0]?.accounts?.map((account: any) => {
      const toggled = !!personalAccountIds?.find((id) => id === account?.id);
      const handleToggle = () => {
        if (!toggled) {
          setPersonalAccountIds([...personalAccountIds, account?.id]);
        } else {
          setPersonalAccountIds(
            personalAccountIds?.filter((id) => id !== account?.id)
          );
        }
      };
      return (
        <Box key={account?.id} className={classes.toggleContainer}>
          <Box>
            <Text variant={TextVariant.medium}>{account?.name || ""}</Text>
            <Box style={{ height: "4px" }} />
            <Text
              className={classes.toggleSubtitle}
              variant={TextVariant.small}
            >{`xxx${account?.mask}`}</Text>
          </Box>
          <Box>
            <ToggleSwitch isChecked={!!toggled} onSwitch={handleToggle} />
          </Box>
        </Box>
      );
    });
  };

  return (
    <animated.div style={springProps}>
      <FormLayout
        subtitle={t("BANK_ACCOUNT_SELECTION.SUBHEADING", { ns: "onboarding" })}
        title={t("BANK_ACCOUNT_SELECTION.HEADING", { ns: "onboarding" })}
      >
        {errorCode && (
          <ErrorToast
            message={
              <>
                Sorry, an error occurred while updating your account. Please try
                again or{" "}
                <a href="mailto:support@betterwithbenji.com?subject=Account%20error&body=There was an error updating my account.">
                  contact our support team
                </a>{" "}
                if you aren’t able to continue.
              </>
            }
          />
        )}
        {renderItems()}
        <Box style={{ height: defaultTheme.spacing[3] }} />
        <ActionRedirect
          isLoading={addBankConnection.isLoading || !businesses}
          primaryLabel={t("BANK_ACCOUNT_SELECTION.PRIMARY_CTA", {
            ns: "onboarding",
          })}
          goToNextStep={() => {
            try {
              trackAnalyticsEvent(AMP_EVENTS.onboardingBusinessAccounts, {
                [AMP_PROPERTIES.accounts]: accountMap?.map(
                  (account) => account?.subtype
                ),
                [AMP_PROPERTIES.institution]:
                  accountMap?.[0]?.institution?.name,
              });
            } catch (err) {
              console.error(err);
            }
            handleSubmit();
          }}
        />
      </FormLayout>
    </animated.div>
  );
};

const useStyles = makeStyles((theme) => ({
  hint: {
    marginBottom: "24px",
  },
  slider: {
    marginBottom: "16px",
  },
  toggleContainer: {
    alignItems: "center",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
  },
  toggleLeftSide: {},
  toggleSubtitle: {
    color: defaultTheme.colours.ink100,
  },
}));

export default BusinessAccounts;
