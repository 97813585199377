import React, { FC, useEffect } from "react";
import FormLayout from "./formLayout";
import { FormControl } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { AMP_EVENTS, AMP_PROPERTIES } from "../../../services/types";
import { useAnalytics } from "../../../services/analytics";
import ActionRedirect from "../components/ActionRedirect";
import { IBasicStepProps } from "../types";
import SliderSqFt from "../../../components/Slider/SqFt";

const HomeSqFt: FC<IBasicStepProps> = ({
  animated,
  decrementStep,
  formik,
  incrementStep,
  springProps,
  t,
}) => {
  const classes = useStyles({});
  const { trackAnalyticsEvent, trackGAPage } = useAnalytics();
  useEffect(() => {
    try {
      trackGAPage(AMP_EVENTS.onboardingHomeOfficeSqFt);
    } catch (err) {
      console.error(err);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <animated.div style={springProps}>
      <FormLayout
        subtitle={t("HOME_SQ_FT.SUBHEADING", { ns: "onboarding" })}
        title={t("HOME_SQ_FT.HEADING", { ns: "onboarding" })}
      >
        <FormControl className={classes.slider}>
          <SliderSqFt
            // className={}
            step={5}
            onChangePercentage={(e: any, value: any) =>
              formik?.setFieldValue("homeOfficeSqFt", value)
            }
            value={formik.values.homeOfficeSqFt}
          />
        </FormControl>
        <ActionRedirect
          isDisabled={formik.values.homeOfficeSqFt === 0}
          goToPreviousStep={decrementStep}
          goToNextStep={() => {
            try {
              trackAnalyticsEvent(AMP_EVENTS.onboardingHomeOfficeSqFt, {
                [AMP_PROPERTIES.sqft]: formik.values.homeOfficeSqFt,
              });
            } catch (err) {
              console.error(err);
            }
            incrementStep();
          }}
          primaryLabel={t("HOME_SQ_FT.PRIMARY_CTA", {
            ns: "onboarding",
          })}
        />
      </FormLayout>
    </animated.div>
  );
};

const useStyles = makeStyles((theme) => ({
  hint: {
    marginBottom: "24px",
  },
  slider: {
    marginBottom: "16px",
  },
}));

export default HomeSqFt;
