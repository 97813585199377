import React, { FC, useEffect, useState } from "react";
import FormLayout from "./formLayout";
import { Box, CircularProgress } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import {
  AMP_EVENTS,
  AMP_PROPERTIES,
  BENJI_API_KEYS,
  IOccupation,
} from "../../../services/types";
import { useAnalytics } from "../../../services/analytics";
import Selection from "../../../components/Selection";
import ActionRedirect from "../components/ActionRedirect";
import defaultTheme from "../../../styles/theme";
import Input from "../../../components/Input";
import { fetchOccupationsList } from "../../../services/benji";
import { useQuery } from "react-query";
import useDebounce from "../../../hooks/useDebounce";
import { IBasicStepProps } from "../types";
import Text, { TextVariant } from "../../../components/Text";

const Occupations: FC<IBasicStepProps> = ({
  animated,
  decrementStep,
  formik,
  incrementStep,
  springProps,
  t,
}) => {
  const classes = useStyles({});
  const [query, setQuery] = useState("");
  const debouncedQuery = useDebounce(query, 350);

  const { trackAnalyticsEvent, trackGAPage } = useAnalytics();
  useEffect(() => {
    try {
      trackGAPage(AMP_EVENTS.onboardingBusinessActivities);
    } catch (err) {
      console.error(err);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { data, isLoading } = useQuery(BENJI_API_KEYS.PROFESSIONS, () =>
    fetchOccupationsList()
  );
  const occupations: Array<IOccupation> = data?.occupations;

  const selectionOptions = occupations
    ?.filter(
      (occupation) =>
        query === "" ||
        occupation?.name?.toLowerCase()?.includes(debouncedQuery?.toLowerCase())
    )
    ?.map(({ emoji, id, name }) => {
      const isActive = formik?.values?.occupations?.has(id);
      return (
        <Selection
          key={id}
          className={classes.selectionItem}
          isSelected={isActive}
          label={name}
          startAdornment={<Text variant={TextVariant.medium}>{emoji}</Text>}
          onChange={() => {
            if (isActive) {
              if (formik.values.occupations?.delete(id)) {
                formik.setFieldValue("occupations", formik.values.occupations);
              }
            } else {
              formik.setFieldValue(
                "occupations",
                formik.values.occupations?.add(id)
              );
            }
          }}
          value={name}
        />
      );
    });

  return (
    <animated.div style={springProps}>
      <FormLayout
        subtitle={t("PROFESSIONAL_ACTIVITY.SUBHEADING", {
          ns: "onboarding",
        })}
        title={t("PROFESSIONAL_ACTIVITY.HEADING", { ns: "onboarding" })}
      >
        {!isLoading ? (
          <>
            <Input
              className={classes.searchField}
              name="search"
              label="Search"
              onChange={(e) => {
                setQuery(e.target.value || "");
              }}
              onClear={() => setQuery("")}
              value={query}
            />
            {selectionOptions}
            <ActionRedirect
              isDisabled={formik.values.occupations.size === 0}
              goToPreviousStep={decrementStep}
              goToNextStep={() => {
                if (
                  formik.values.occupations?.has(
                    "27767ada-53ab-48ab-aaa9-dd9dd0b03dd1"
                  )
                ) {
                  formik.setFieldValue("cogs", true);
                } else {
                  formik.setFieldValue("cogs", undefined);
                }
                try {
                  trackAnalyticsEvent(AMP_EVENTS.onboardingBusinessActivities, {
                    [AMP_PROPERTIES.businessActivities]:
                      formik.values.occupations,
                  });
                } catch (err) {
                  console.error(err);
                }
                incrementStep();
              }}
              primaryLabel={t("PROFESSIONAL_ACTIVITY.PRIMARY_CTA", {
                ns: "onboarding",
              })}
            />
          </>
        ) : (
          <Box className={classes.spinnerContainer}>
            <CircularProgress />
          </Box>
        )}
      </FormLayout>
    </animated.div>
  );
};

const useStyles = makeStyles((theme) => ({
  closeIcon: {
    marginRight: "0",
  },
  label: {
    color: defaultTheme.colours.ink400,
  },
  searchField: {
    marginBottom: "24px",
    marginTop: 0,
    padding: 0,

    "& .MuiInputBase-root": {
      paddingRight: "6px",
    },
  },
  selectionItem: {
    marginBottom: "8px",
  },
  spinnerContainer: {
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    margin: "30% 0",
  },
}));

export default Occupations;
