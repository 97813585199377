import { useEffect, useRef } from "react";
import { env } from "../utils/env";
import { identify, Identify, init, track } from "@amplitude/analytics-browser";
import { usePostHog } from "posthog-js/react";

import { BaseTransport } from "@amplitude/analytics-core";
import { Payload, Response, Transport } from "@amplitude/analytics-types";

export class FetchTransport extends BaseTransport implements Transport {
  async send(serverUrl: string, payload: Payload): Promise<Response | null> {
    /* istanbul ignore if */
    if (typeof fetch === "undefined") {
      throw new Error("FetchTransport is not supported");
    }
    const options: RequestInit = {
      headers: {
        "Content-Type": "application/json",
        Accept: "*/*",
      },
      body: JSON.stringify(payload),
      method: "POST",
    };
    const response = await fetch(serverUrl, options);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const responsePayload: Record<string, any> = await response.json();
    return this.buildResponse(responsePayload);
  }
}

export const useAnalytics = () => {
  const initialized = useRef(false);
  const posthog = usePostHog();

  useEffect(() => {
    if (!initialized.current) {
      initTracking();
    }
  }, []);

  const setUserId = (userId: string, email: string) => {
    if (userId === "") {
      return;
    }
    posthog.identify(userId, {
      email,
    });
    identify(new Identify().set("userId", userId));
  };

  const initTracking = () => {
    if (env("NODE_ENV") !== "test") {
      init(env("REACT_APP_AMPLITUDE"), "", {
        serverUrl: `${env("REACT_APP_API_BASE_URL")}/api/v2/amplitude`,
      });
      initialized.current = true;
    }
  };

  const trackAnalyticsEvent = (
    eventName: string,
    eventProperties?: Record<string, any>
  ) => {
    track(eventName, eventProperties);
    posthog.capture(eventName, eventProperties);
  };

  const trackGAPage = (page: string) => {
    // @ts-ignore
    if (window?.analytics?.page) {
      // @ts-ignore
      window?.analytics?.page(page);
    }
  };

  return {
    initTracking,
    setUserId,
    trackAnalyticsEvent,
    trackGAPage,
  };
};
